body {
  margin: 0;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a.navbar-brand {
  text-align: right;
  display:    inline-table;
  position:   relative;
}

nav.navbar{
  padding: 18px 0;
  width : 100%;
  top : 0;
  z-index : 99;
  transition : 0.3s ease-in-out;
}

nav.navbar a.navbar-brand {
  width: 10%;
  font-weight: 600;
  font-size : 25px;
}

nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  font-size: 18px;
  padding : 0 25px;
  letter-spacing: 0.8px;
}

nav.navbar span.navbar-text {
  display: flex;
  align-items: center;
}

nav.navbar span.navbar-text button {
  color : white;
  padding : 18px 34px;
  font-size : 18px;
  margin-left: 18px;
  position: relative;
  border: 1px solid white;
  /* border-radius : 35px; */
  background-color: transparent;
  transition: 0.32s ease-in-out;
}

.no-border {
  border: 0px !important;
}

.list-group {
  border: 0px !important;
  border-radius: 0px !important;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  margin-bottom: 0 !important;
}

.react-datepicker__navigation {
  top: 10px !important;
}

.form-control.is-invalid {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc4c64%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc4c64%27 stroke=%27none%27/%3e%3c/svg%3e") !important;
}